<template>
  <!--begin::Organization-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.ORGANIZATION") }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.ORGANIZATION_DESC") }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <b-button v-if="isCanEdit"
            @click="handleOk"
            class="btn bg-success text-light font-weight-bolder font-size-sm">{{ $t("MANAGEMENT.SAVE_BUTTON") }}</b-button>
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="row">
        <div class="col-lg-9 col-xl-7">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="form-group row align-items-center mb-10"
                          label-for="name-input"
                          :invalid-feedback="$t('MANAGEMENT.ORGANIZATION_INPUT_INVALID_FEEDBACK')"
                          :state="nameState">
              <label class="col-md-4 col-lg-3 col-xl-3 col-form-label font-weight-bolder">{{ $t("MANAGEMENT.ORGANIZATION_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></label>
              <div class="col-md-8 col-lg-9 col-xl-9">
                <b-form-input
                    :disabled="!isCanEdit"
                    id="name-input"
                    :placeholder="$t('MANAGEMENT.ORGANIZATION_INPUT_PLACEHOLDER')"
                    v-model="organization.name"
                    :state="nameState"
                    required
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row align-items-start mb-10">
              <label class="col-md-4 col-lg-3 col-xl-3 col-form-label font-weight-bolder">{{ $t("MANAGEMENT.LOGO_INPUT_TITLE") }}</label>
              <div class="col-md-8 col-lg-9 col-xl-9">
                <div class="image-input image-input-outline" id="org_avatar">
                  <div v-if="!isEditImage"
                      class="image-input-wrapper"
                      :style="{ backgroundImage: `url(data:image/png;image/jpeg;image/jpg;image/svg+xml;base64,${photo})` }"></div>
                  <div v-if="isEditImage"
                      class="image-input-wrapper"
                      :style="{ backgroundImage: `url(${photo})` }"></div>
                  <label
                      v-if="isCanEdit"
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar">
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input
                        id="image-file"
                        type="file"
                        name="org_avatar"
                        accept=".png, .jpg, .jpeg, .svg"
                        @change="onFileChange($event)"
                    />
                    <input type="hidden" name="org_avatar_remove" />
                  </label>
                  <span
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar">
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                      v-if="isCanEdit && organization.logo"
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="remove"
                      data-toggle="tooltip"
                      :title="$t('MANAGEMENT.DELETE_BUTTON')"
                      @click="onFileDelete">
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
                <span style="font-size: 0.8rem;" class="form-text text-muted">{{ $t("MANAGEMENT.LOGO_INPUT_DESC") }}</span>
                <span v-if="imageState" class="text-danger">{{ $t("MANAGEMENT.LOGO_INPUT_WARNING") }}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Organization-->
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_ORGANIZATION, POST_ORGANIZATION} from "@/core/services/store/management/organization.module";

export default {
  name: "Organization",
  mounted() {
    this.$store.dispatch(GET_ORGANIZATION).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.ORGANIZATION") }]);
    });
  },
  data() {
    return {
      isEditImage: false,
      isImgTouched: false,
      nameState: null,
      imageState: null,
      default_photo: process.env.BASE_URL + "media/default-org-logo.png",
      current_photo: null
    };
  },
  computed: {
    ...mapGetters([
      "organization"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]));
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
    photo() {
      if (!this.isEditImage) this.current_photo = this.organization.logo;
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    }
  },
  methods: {
    onFileChange(e) {
      this.isEditImage = true;
      const file = e.target.files[0];
      if (file.size > 200000) {
        this.imageState = true;
        setTimeout(() => this.imageState = false, 5000)
        e.target.files[0] = null;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.organization.logo = file;
        this.isImgTouched = true;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onFileDelete() {
      this.isEditImage = true;
      this.isImgTouched = true;
      this.organization.logo = null;
      this.current_photo = null;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.organization.logo === null || typeof this.organization.logo === "string") {
        fetch(this.photo)
            .then(res => res.blob())
            .then(blob => {
              this.organization.logo = new File([blob], "Default logo", {type: "image/png"});
              let formData  = new FormData();
              formData.append("name", this.organization.name);
              if (this.isImgTouched) formData.append("logo", this.organization.logo);
              this.$store.dispatch(POST_ORGANIZATION, { form: formData });
            })
      } else {
        let formData  = new FormData();
        formData.append("name", this.organization.name);
        formData.append("logo", this.organization.logo);
        this.$store.dispatch(POST_ORGANIZATION, { form: formData });
      }

    }
  }
}
</script>

<style scoped>

</style>
